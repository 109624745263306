<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";
import { getRoute } from "~/lib/routes";

const { isOpen } = useShoppingCartView();

const { cartItems, totalPrice, count } = useCart();
const { onBeginCheckout } = useGtagEvents();

const goToCheckout = async () => {
	 await navigateTo(getRoute("checkoutLogin"), {
		replace: true,
		external: true
	});
	try {
		onBeginCheckout();
	} catch (error) {
		console.error("Error onBeginCheckout", error);
	}
	isOpen.value = false;
};
</script>

<template>
	<ClientOnly>
		<KippieSidebar v-model="isOpen" color="white" placement="right" key="shopping-cart">
			<template #top>
				<button class="flex flex-col gap-2 items-center mx-auto">
					<Typography variant="h2">Winkelmandje</Typography>
					<span class="">
						<span class="text-gray"> {{ count }} {{ count === 1 ? "artikel" : "artikelen" }} | </span>
						<span>{{ formatPrice(totalPrice, true) }}</span>
					</span>
				</button>
			</template>
			<div class="flex flex-col gap-6 pb-16 lg:pb-0">
				<ErrorMessageSection />

				<CartItemListSection />

				<CouponSection v-if="cartItems?.length" />

				<Divider />

				<SummarySection />

				<div
					class="!fixed sm:!relative z-20 w-full bottom-2 left-0 p-2 sm:bg-white bg-sand no-underline shadow sm:shadow-none"
				>
					<KippieButton
						block
						color="yellow"
						size="small"
						@click="goToCheckout"
						:disabled="cartItems?.length === 0"
					>
						Doorgaan naar bestellen
					</KippieButton>
				</div>
				<UspSection />
			</div>
		</KippieSidebar>
	</ClientOnly>
</template>
